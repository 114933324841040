import React from 'react'
import { stringifyInput } from '../../helpers/StringHelper'

interface Props {
  label?: React.ReactNode
  dropdownElement?: React.ReactNode
  onExpand?: () => void
  onHide?: () => void
  overflowHidden?: boolean
  buttonType?: 'reset' | 'submit' | 'button'
  buttonClassName?: string
  className?: string
}
interface State {
  expanded: boolean
  expandTop: boolean
}
class ClickDropdown extends React.Component<Props, State> {
  wrapperRef: any

  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      expandTop: false,
    }
    this.hideDropdown = this.hideDropdown.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.hideDropdown)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.hideDropdown)
  }

  toggleDropdown() {
    const pageScrollHeight = document.body.scrollHeight

    this.setState({ expanded: !this.state.expanded }, () => {
      if (this.props.onExpand) {
        this.props.onExpand()
      }

      const dropDown = document.querySelector('.click-dropdown-menu') as HTMLElement

      const dropDownBottomPos = dropDown
        ? dropDown.getBoundingClientRect().bottom + document.documentElement.scrollTop
        : null

      if (dropDownBottomPos && dropDownBottomPos > pageScrollHeight) {
        this.setState(state => ({ expandTop: !state.expandTop }))
      }
    })
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  hideDropdown(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      document.getElementsByClassName('ReactModal__Overlay').length == 0 &&
      this.state.expanded === true
    ) {
      this.setState({ expanded: false }, () => {
        if (this.props.onHide) {
          this.props.onHide()
        }
      })
    }
  }

  render() {
    const { label, children, overflowHidden, buttonType, buttonClassName } = this.props
    const { expanded, expandTop } = this.state

    const classNames = ['click-dropdown', this.props.className]
    if (expanded) {
      classNames.push('click-dropdown-expanded')
    }

    return (
      <div className={classNames.join(' ')} ref={this.setWrapperRef}>
        {!!this.props.label && (
          <button
            type={buttonType}
            className={`click-dropdown-toggle ${stringifyInput(buttonClassName)}`}
            onClick={this.toggleDropdown}
          >
            {label}
          </button>
        )}
        {!!this.props.dropdownElement && <div onClick={this.toggleDropdown}>{this.props.dropdownElement}</div>}
        {expanded && (
          <div
            className={`click-dropdown-menu ${overflowHidden ? 'overflow-hidden' : ''} ${
              expandTop ? 'click-dropdown-expand-top' : ''
            }`}
          >
            {typeof children === 'function' ? children(this.toggleDropdown) : children}
          </div>
        )}
      </div>
    )
  }
}

export default ClickDropdown
